<template>
    <div>
        <LoaderDefault v-if="this.isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/Services'" :titleHeader="'Formulario de registro'" />

            <hr>
            <section class="sec-anexos" id="sec-anexos">
                <div class="header-sec-anexos">
                    <h1 class="title-section-anexos">Subir archivos</h1>
                    <i class="fa-solid fa-circle-info"></i>
                </div>
                <div class="content-form">
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="name">Nombre *</label>
                        <input id="name" v-model="dataService.name" class="input input-anexo" name="name" type="text"
                            placeholder="Nombre" required>
                    </div>
                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="typeSercice">Servicio *</label>
                        <select name="typeSercice" id="typeSercice" v-model="typeService" class="input input-anexo"
                            required>
                            <option value="acueducto">acueducto</option>
                            <option value="alcantarillado">alcantarillado</option>
                            <option value="aseo">aseo</option>
                        </select>

                    </div>

                    <div class="content-inputs" id="content-inputs">
                        <label class="label" id="label" for="date">Fecha de publicación *</label>
                        <input id="date" v-model="dataService.date" class="input input-anexo" name="date" type="date"
                            placeholder="Feca de publicación" required>
                    </div>

                    <div class="div-select-custom">
                        <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                            class="inputfile inputfile-5 input-anexo" required />
                        <label id="label" for="file-5" class="label-file label">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                    viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                    </path>
                                </svg>
                            </figure>
                        </label>
                        <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                    </div>
                </div>
            </section>
            <hr>
            <button class="btn-save-data" @click="saveService()">Guardar Nuevo servicio</button>
        </div>
    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import { db, storage } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault,
    },
    data() {
        return {
            isLoading: false,
            selectedFile: File,

            dataService: {
                _idName: '',
                name: '',
                date: '',
                urlPdf: '',
            },

            typeService: ''
        }
    },
    methods: {
        async saveService() {
            if (this.validaFormSecondForm()) {
                this.isLoading = true
                this.dataService._idName = this.dataService.name
                const collectionRef = await db.collection('services');
                const docRef = await collectionRef.doc(this.typeService).get()

                if (docRef.exists) {
                    const existingServices = docRef.data().services || [];

                    const storageRef = storage.ref();
                    const pdfRef = storageRef
                        .child('pdfsServices')
                        .child(this.typeService)
                        .child(this.dataService._idName);
                    await pdfRef.put(this.selectedFile);
                    const pdfUrl = await pdfRef.getDownloadURL();

                    this.dataService.urlPdf = pdfUrl
                    const updatedServices = [...existingServices, this.dataService];

                    await collectionRef.doc(this.typeService).update({
                        services: updatedServices
                    });

                    this.$swal
                        .fire({
                            title: 'Servicio creado',
                            text: 'Nuevo servicio de ' + this.typeService + " creado con exito.",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    this.isLoading = false
                    this.dataService = {}
                }
            }
        },

        navToIndexTender() { this.$router.push('IndexTender') },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                // this.selectedFile = File;
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },

        validaFormSecondForm() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            // const elementSelectPdf = document.getElementById('name-pdf-selected')

            (labels.length);

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    // elementSelectPdf.style.color = "red"
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });

                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = ''
                    // elementSelectPdf.style.color = ''
                }
            }
            return true;
        }
    },
}
</script>